<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="true"
      :items="items"
      class="dataTable elevation-1"
      calculate-widths
      hide-default-footer
      disable-pagination
      fixed-header
      mobile-breakpoint="600"
      :dense="true"
      :single-expand="false"
      item-key="stopNumber"
      :show-expand="parcel"
      :expanded="$route.name === 'TripInfo' && !$vuetify.breakpoint.mdAndUp && items || []"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-8">
          <v-row class="d-flex" v-if="item.shipmentDetails">
            <v-col cols="12" md="6">
              <v-card class="pa-4">
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <h2 class="ml-3">{{ $t("parcel_details") }}</h2>
                    <a
                      :href="`https://api.whatsapp.com/send?phone=+58${item.shipmentDetails.responsible_phone}&text=Hola, %20te%20estoy%20enviando%20%20por%20Ridery`"
                      target="_blank"
                    >
                      <v-icon>{{ icons.mdiWhatsapp }}</v-icon>
                    </a>
                  </v-col>
                </v-row>
                <v-list>
                  <v-list-item three-line>
                    <v-row>
                      <v-col md="5" cols="12">
                        <v-list-item-content
                          :style="
                            $vuetify.breakpoint.mdAndUp
                              ? 'border-right: solid 1px #3bd4ae'
                              : 'border-bottom: solid 1px #3bd4ae; padding-bottom: 30px;'
                          "
                        >
                          <v-list-item-title>{{
                            $t("parcel_details_table")
                          }}</v-list-item-title>
                          <!-- Nombre y apellido -->
                          <v-list-item-subtitle>
                            {{
                              `${item.shipmentDetails.responsible_first_name} ${item.shipmentDetails.responsible_last_name}`
                            }}
                          </v-list-item-subtitle>
                          <!-- telefono -->
                          <v-list-item-subtitle>
                            <a
                              :href="`tel:+58${item.shipmentDetails.responsible_phone}`"
                            >
                              {{ item.shipmentDetails.responsible_phone }}</a
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-col>
                      <v-col md="7" cols="12">
                        <v-list-item-content class="pl-md-4">
                          <v-list-item-title>{{
                            $t("parcel_details")
                          }}</v-list-item-title>
                          <!-- detalles -->
                          <p
                            style="color: rgba(94, 86, 105, 0.6);"
                            class="text-justify"
                          >
                            {{ item.shipmentDetails.package_description }}
                          </p>
                        </v-list-item-content>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:header.stopOrigin="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require( !parcel ? '@/views/RunningTrips/assets/IconOriginMap.png' : '@/views/Parcel/assets/IconOriginMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>

      <template v-slot:header.stopDestination="{ header }">
        <span class="d-flex flex-row align-center">
          <v-img
            :src="require(!parcel ? '@/views/RunningTrips/assets/IconDestinationMap.png' : '@/views/Parcel/assets/IconDestinationMap.png')"
            style="max-width: 20px"
          ></v-img>
          {{ header.text }}
        </span>
      </template>

      <template v-slot:header.costTotal="{ header }">
        <v-tooltip top :disabled="showPage">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ header.text }}{{ !showPage ? " *" : "" }}</span
            >
          </template>
          <span>{{ $t("variable_tariffs") }}</span>
        </v-tooltip>
      </template>

      <template #item.stopNumber="{ value }">
        <span>
          <td>
            <v-img
              :src="!parcel ? require(`@/views/Multistop/assets/Stop${value}.png`) : require(`@/views/ParcelMultistop/assets/Stop${value}.png`)"
              max-width="42"
            ></v-img>
          </td>
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              @click="handleRemove(item.stopNumber)"
              :disabled="
                item.stopNumber + 1 !== stateMultiStop.length || loading
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
          <span
            >{{ $t("Delete") }} {{ $t("multistop_stop") }} #
            {{ item.stopNumber }}</span
          >
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiRefresh,
  mdiMapMarker,
  mdiClose,
  mdiWhatsapp,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    showPage: {
      type: Boolean,
      default: false,
    },
    parcel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMapMarker,
        mdiClose,
        mdiWhatsapp,
      },
    };
  },
  computed: {
    ...mapState(["stateMultiStop"]),

    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
  methods: {
    ...mapActions(["removeMultiStopStop", "setServiceTypeOrigin"]),
    handleRemove(index) {
      if (index > 1) {
        this.$dialog.notify.warning(
          `Se ha eliminado la parada #${index} - ${this.stateMultiStop[index].formatted_address}`,
          {
            position: "top-left",
          }
        );
        this.removeMultiStopStop();
      } else {
        this.$dialog.notify.warning(
          `Se ha eliminado la parada #${1} - ${
            this.stateMultiStop[1].formatted_address
          }`,
          {
            position: "top-left",
          }
        );
        this.$dialog.notify.warning(
          `Se ha eliminado la dirección de origen - ${this.stateMultiStop[0].formatted_address}`,
          {
            position: "top-left",
          }
        );
        while (this.stateMultiStop.length > 0) {
          this.removeMultiStopStop();
        }
        this.setServiceTypeOrigin(null);
      }
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
</style>
